import IconInstagram from "@ef-global/web-ui-react/lib/icons/IconSocialInAlt";
import IconTwitter from "@ef-global/web-ui-react/lib/icons/IconSocialTw";
import IconFacebook from "@ef-global/web-ui-react/lib/icons/IconSocialFbAlt";
import IconYoutube from "@ef-global/web-ui-react/lib/icons/IconSocialYt";
import styles from "./social-links.module.scss";

interface Props {
  instagram?: string;
  twitter?: string;
  facebook?: string;
  youtube?: string;
  children?: React.ReactNode;
}

const SocialLinks = (props: Props) => {
  const { instagram, twitter, facebook, youtube, children } = props;
  return (
    <div className={styles.socialLinks}>
      {children}
      {instagram && (
        <a
          href={instagram}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Instagram page`}
          className={styles.socialLink}
        >
          <IconInstagram />
        </a>
      )}
      {twitter && (
        <a
          href={twitter}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Twitter page`}
          className={styles.socialLink}
        >
          <IconTwitter />
        </a>
      )}
      {facebook && (
        <a
          href={facebook}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Facebook page`}
          className={styles.socialLink}
        >
          <IconFacebook />
        </a>
      )}
      {youtube && (
        <a
          href={youtube}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Youtube page`}
          className={styles.socialLink}
        >
          <IconYoutube />
        </a>
      )}
    </div>
  );
};

export default SocialLinks;
