import React, { useContext } from "react";
import Logo from "../Header/Logo/assets/logo-mono.svg";
import SocialLinks from "../SocialLinks/social-links";
import { SettingsContext } from "@/context/SettingsContext";
import styles from "./footer.module.scss";
import Button from "../Button/button";

const Footer = () => {
  const { settings } = useContext(SettingsContext);

  return (
    <footer className={`${styles.proCyclingFooter} layout-grid site-footer`}>
      <div className={styles.proCyclingFooter__inner}>
        <div className={styles.proCyclingFooter__main}>
          <div className={styles.proCyclingFooter__logo}>
            <span className={styles.logo}>
              <Logo />
            </span>
            <Button
              link={`mailto:${settings?.content?.contact_email}`}
              size={"small"}
              className="u-show-s-only footer-contact-button"
            >
              Contact us
            </Button>
          </div>
          <div className={styles.proCyclingFooter__social}>
            <a
              href="https://www.ef.com/legal/cookie-policy/"
              target="_blank"
              rel="noopener noreferrer"
              className="u-show-s-only"
            >
              Cookies
            </a>
            <SocialLinks
              instagram={settings?.content?.instagram}
              youtube={settings?.content?.youtube}
              twitter={settings?.content?.twitter}
              facebook={settings?.content?.facebook}
            >
              <Button
                link={`mailto:${settings?.content?.contact_email}`}
                size={"small"}
                className="u-show-m-up footer-contact-button"
              >
                Contact us
              </Button>
            </SocialLinks>
          </div>
        </div>
        <div className={styles.proCyclingFooter__colophon}>
          <div className={styles.proCyclingFooter__links}>
            <a
              href="https://www.ef.com/legal/cookie-policy/"
              target="_blank"
              rel="noopener noreferrer"
              className="u-show-m-up"
            >
              Cookies
            </a>
            <a
              href="https://www.teamefcoaching.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.ef.com/"
              className="u-show-m-up"
              target="_blank"
              rel="noopener noreferrer"
            >
              ef.com
            </a>
          </div>
          <span>
            © EF Education First {new Date().getFullYear()}. All rights
            reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
