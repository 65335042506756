import React from "react";
import Link from "@/components/Link";
import styles from "./button.module.scss";
import classnames from "classnames";

import IconArrowRight from "@ef-global/web-ui-react/lib/icons/IconArrowRight";
import IconArrowDown from "@ef-global/web-ui-react/lib/icons/IconArrowDown";
import IconChevronDown from "@ef-global/web-ui-react/lib/icons/IconChevronDown";
import IconCalendar from "@ef-global/web-ui-react/lib/icons/IconCalendar";
import { MultilinkStoryblok } from "@/types/component-types-sb";

const icons: Record<string, React.ReactNode> = {
  IconArrowRight: <IconArrowRight />,
  IconArrowDown: <IconArrowDown />,
  IconChevronDown: <IconChevronDown />,
  IconCalendar: <IconCalendar />,
};

type NativeComponentProps = Partial<React.ComponentPropsWithoutRef<"button">> &
  Partial<React.ComponentPropsWithoutRef<"a">>;

export interface ButtonProps extends NativeComponentProps {
  icon?: keyof typeof icons;
  size?: "small" | "medium" | "large";
  btnStyle?:
    | "black"
    | "white"
    | "border"
    | "whiteLink"
    | "blackLink"
    | "noBackgroundIcon"
    | "none";
  link?: string | MultilinkStoryblok;
  accent_color?:
    | "accentBlack"
    | "accentWhite"
    | "accentLightGrey"
    | "accentPink"
    | "border"
    | "white";
  className?: string;
  btnType?: "rectangle";
  linkParams?: string;
  lightIcon?: boolean;
  iconGap?: "none";
  fullWidthOnMobile?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  filled?: boolean;
  secondary?: boolean;
}

const Button = (props: ButtonProps) => {
  const {
    icon = "",
    size = "medium",
    btnStyle = "",
    link,
    accent_color = "",
    children,
    className,
    btnType,
    linkParams,
    lightIcon,
    iconGap,
    fullWidthOnMobile,
    ...rest
  } = props;

  const classes = classnames(
    styles.button,
    styles[size],
    styles[btnStyle],
    styles[accent_color],
    styles.block,
    className,
    {
      [styles.lightIcon]: !!lightIcon,
      [styles.disabled]: rest.disabled,
      [styles.rectangle]: btnType === "rectangle",
      [styles.fullWidthOnMobile]: fullWidthOnMobile,
      [styles.pr24]: size === "small" && icon,
    }
  );

  const scrollToAnchor: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    const trimmed = sbLinkUrl.substring(1);
    if (typeof window === "undefined") return;
    const targetEl = document.querySelector(`.section-${trimmed}`) || null;
    targetEl &&
      window.scrollTo({
        top: targetEl.getBoundingClientRect().top + window.scrollY,
        behavior: "smooth",
      });
  };

  const sbLinkUrl =
    typeof link === "object"
      ? link?.url || (link?.cached_url && `/${link.cached_url}`)
      : link || "";

  const linkWithParams = linkParams ? `${sbLinkUrl}?${linkParams}` : sbLinkUrl;

  return sbLinkUrl ? (
    <Link
      href={linkWithParams}
      className={classes}
      {...rest}
      onClick={sbLinkUrl.startsWith("#") ? scrollToAnchor : undefined}
    >
      {children}
      {icons?.[icon] ? (
        <span
          className={classnames(styles.icon, {
            [styles.iconGapNone]: iconGap === "none",
          })}
        >
          {icons[icon]}
        </span>
      ) : null}
    </Link>
  ) : (
    <button className={classes} {...rest}>
      {children}
      {icons?.[icon] ? (
        <span
          className={classnames(styles.icon, {
            [styles.iconGapNone]: iconGap === "none",
          })}
        >
          {icons[icon]}
        </span>
      ) : null}
    </button>
  );
};

export default Button;
